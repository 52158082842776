import { useEffect, useRef, useState } from "react";
import { EAxisType, EChart2DModifierType, ESeriesType, EThemeProviderType, FastColumnRenderableSeries, FastLineRenderableSeries, NumberRange, SciChartSurface } from "scichart";
import { SciChartReact } from "scichart-react";

SciChartSurface.loadWasmFromCDN();
SciChartSurface.setRuntimeLicenseKey(process.env.REACT_APP_SCI_KEY)
SciChartSurface.UseCommunityLicense()
const SciChart = ({x,y,xAxisMax,xAxisMin, signalName}) => {

    const chartRef  = useRef();

    const [sciChartSurface,setsciChartSurface] = useState(null);
    const [wasmContext,setwasmContext] = useState();
 
// const loadScichart =  async () => {
// const { sciChartSurface, wasmContext } = await SciChartSurface.create( `${signalName}_scichart-root` );
// setsciChartSurface(sciChartSurface)
// setwasmContext(wasmContext)

// }
const customTheme = {
    axisBorder: "Transparent",
    axisTitleColor: "#6495ED",
    annotationsGripsBackroundBrush: "white",
    annotationsGripsBorderBrush: "white",
    axis3DBandsFill: "#1F3D6833",
    axisBandsFill: "#1F3D6833",
    axisPlaneBackgroundFill: "Transparent",
    columnFillBrush: "white",
    columnLineColor: "white",
    cursorLineBrush: "#6495ED99",
    defaultColorMapBrush: [
        { offset: 0, color: "DarkBlue" },
        { offset: 0.5, color: "CornflowerBlue" },
        { offset: 1, color: "#FF22AA" }
    ],
    downBandSeriesFillColor: "#52CC5490",
    downBandSeriesLineColor: "#E26565FF",
    downBodyBrush: "white",
    downWickColor: "white",
    gridBackgroundBrush: "white",
    gridBorderBrush: "white",
    labelBackgroundBrush: "#6495EDAA",
    labelBorderBrush: "#6495ED",
    labelForegroundBrush: "#EEEEEE",
    legendBackgroundBrush: "#1D2C35",
    lineSeriesColor: "white",
    loadingAnimationBackground: "#0D213A",
    loadingAnimationForeground: "#6495ED",
    majorGridLineBrush: "#1F3D68",
    minorGridLineBrush: "#102A47",
    mountainAreaBrush: "white",
    mountainLineColor: "white",
    overviewFillBrush: "white",
    planeBorderColor: "white",
    rolloverLineBrush: "#FD9F2533",
    rubberBandFillBrush: "#99999933",
    rubberBandStrokeBrush: "#99999977",
    sciChartBackground: "white",
    scrollbarBackgroundBrush: "white",
    scrollbarBorderBrush: "white",
    scrollbarGripsBackgroundBrush: "white",
    scrollbarViewportBackgroundBrush: "white",
    scrollbarViewportBorderBrush: "white",
    shadowEffectColor: "white",
    textAnnotationBackground: "#6495EDAA",
    textAnnotationForeground: "#EEEEEE",
    tickTextBrush: "#6495ED",
    upBandSeriesFillColor: "white",
    upBandSeriesLineColor: "white",
    upBodyBrush: "#6495EDA0",
    upWickColor: "#6495ED"
}
useEffect(() => {
    if(chartRef.current){
        setsciChartSurface(chartRef.current.sciChartSurface)
        console.log( chartRef.current.sciChartSurface )
    }
},[chartRef])

useEffect(() => {

    // SciChartSurface.xAxes.get(0).visibleRange(xAxisMin, xAxisMax)
    // if(sciChartSurface){
    //     sciChartSurface.applyTheme(customTheme);

    // }
},[xAxisMax,xAxisMin,sciChartSurface]) 
    



    return (
       
            <SciChartReact
            ref={chartRef}
            style={{ width: "100%", height: "100%" }}
            config={{
            xAxes: [{ id: "primary_xaxis", type: EAxisType.NumericAxis , sciChartBackground: "white" , visibleRange: new NumberRange(xAxisMin, xAxisMax) }],
            yAxes: [{ id: "primary_yaxis", type: EAxisType.NumericAxis  }],
            surface: {
                theme :  {
                    background: "#FFFFFF"
            },
            },   
            series: [
                {
                    
                    type: ESeriesType.SplineMountainSeries,
                    
                    options: {
                        fill: "#3ca832",
                        stroke: "#eb911c",
                    
                        strokeThickness: 4,
                        opacity: 0.4
                    },
                
                    xyData: { xValues: x, yValues: y }, 
                    xAxisId : "primary_xaxis", 
                    yAxisId : "primary_yaxis"
                }, 
                // {
                //     type: ESeriesType.SplineMountainSeries,
                //     options: {
                //         fill: "#3ca832",
                //         stroke: "#eb911c",
                //         strokeThickness: 4,
                //         opacity: 0.4
                //     },
                //     xyData: { xValues: x1, yValues: y1 }, 
                //     xAxisId : "primary_xaxis", 
                //     yAxisId : "secondary_yaxis"
                // }, 


            ],
            
            modifiers: [
                
                { type: EChart2DModifierType.ZoomPan, options: { enableZoom: true } },
                { type: EChart2DModifierType.MouseWheelZoom },
                { type: EChart2DModifierType.ZoomExtents }
            ]
        }}
    />
    )

}


export default SciChart;

