import React, { Component, useEffect, useState } from 'react';
import { Row, Col, Container, Button, ModalHeader, ModalFooter, Modal, ModalBody } from "reactstrap";
import html2canvas from 'html2canvas';
import { Link, useParams,useNavigate, useSearchParams } from 'react-router-dom';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import ChartHeader from '../../component/ChartHeader';
import Chart from './Chart';
import { API_URL } from '../../../config';
import SciChart from '../sci-chart/SciChart';
import ChartSci from './ChartSci';
const ChartTable = () => {
    const accessToken = localStorage.getItem('accessToken');
    const [searchParams] = useSearchParams()
    const showsci = searchParams.get('showsci')
    const { config, session, record, currentConfig, showclock } = useParams();
    const [graphs, setgraphs] = useState([]);
    const [notes, setNotes] = useState(null);
    const [altName, setAltName] = useState(null)
    const [reportName, setReportName] = useState(null);
    const [showHeader, setShowHeader] = useState(false);
    const [sessionDate, setSessionDate] = useState(null);
    const userId = localStorage.getItem('user_id');
    const showActualTime = useState(showclock == 1 ? true : false);
    const [isOpen, setIsOpen] = useState(false);
    const [defaultaltsave, setDefaultaltsave] = useState(0);
    const [requiredField, setrequiredField] = useState(false);
    const [zoomEnabled, setZoomEnabled] = useState(true);
    const [dragMode, setDragMode] = useState('pan');
    const [singleAction, setSingleAction] = useState();
    const navigate = useNavigate();

    const handleDataFromChild = (data) => {
        setSingleAction(data);
    };
    // const [value, setValue] = useState(0);
    // const [point, setPoint] = useState(25);
    // const [color, setColor] = useState();
    const [signalConfig, setSignalConfig] = useState({})
    const [changesMade, setChangesMade] = useState(false)
    
    const [signalStat, setSignalStat] = useState({})
    const [showSignalStat, setShowSignalStat] = useState(false)

    const [requestProcessingModal, setrequestProcessingModal] = useState(false);
    const requestProcessingModalToggle = () => setrequestProcessingModal(!requestProcessingModal);

    const [savingReportConfirmation, setSavingReportConfirmation] = useState(false);
    const savingReportConfirmationToggle = () => setSavingReportConfirmation(!savingReportConfirmation);

    const [savingAlternateConfirmation, setSavingAlternateConfirmation] = useState(false);
    const savingAlternateConfirmationToggle = () => setSavingAlternateConfirmation(!savingAlternateConfirmation);


    const [requestProcessedModal, setrequestProcesedModal] = useState(false);
    const requestProcessedModalToggle = () => setrequestProcesedModal(!requestProcessedModal);


    const setConfig = (_signal, data) => {
        let _temp = signalConfig;
        _temp[_signal] = {
            color: data.color,
            type: data.type,
            avg: data.avg,
            xmin: data.xmin / 1e3,
            thick: data.thick,
            xextreme: data.xextreme / 1e3,
            xmax: data.xmax / 1e3,
            ymin: data.ymin,
            ymax: data.ymax,
            record: data.record,
            graph_order: data.graph_order,
            comment: data.comment,
            row: data.row,
            clientSerial: data.clientSerial,
            col: data.col,
            xrange: data.xrange,
            units: data.units,
            annotation: data.annotation,
            grid: data.grid,
            inverty: data.inverty,
            yposition: data.yposition,
            lineType: data.lineType,
            thresholdtLine: data.thresholdtLine,
            thresholdtcolor: data.thresholdtcolor,
            stat: data.stat,
            thresholdthick: data.thresholdthick,
            thresholdvalue: data.thresholdvalue
        }
        // console.log("signal config",_temp);
        setSignalConfig(_temp)
    }


    const setStats = (_signal, data) => {

        // // console.log("signal data",data)
        let _temp = signalStat;
        let _tempData = [];
        data.map((v, i) => {
            _tempData.push({
                x: v.x,
                mean: v.mean,
                median: v.median,
                sd: v.sd,
            })
        })
        _temp[_signal] = _tempData;
        setSignalStat(_temp)
        setTimeout(() => {
            setShowHeader(true);
        }, 1000 * graphs.length);
    }

    useEffect(() => {
        reportChart();


    }, []);

    const reportChart = () => {
        fetch(API_URL + "/report/config?report_id=" + currentConfig,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
            }
        ).then((response) => {
            if (response.status == 200) {
                response.json().then((resp) => {
                    // console.warn("result", resp);
                    setgraphs(resp.graphs)


                });
            }
            else if (response.status == 401) {
                logout()
            }
            else {
                console.log("network error")
            }



        })
    }
    const logout = () => {
        localStorage.clear();
        window.location.reload();
    }


    const exportExcel = () => {
        setrequestProcesedModal(true);

    }

    const performAction = () => {
        if (singleAction == "reportConfig") {
            reportconfigupdate();
        }
        else if (singleAction == "altConfig") {
            reportconfigalternateupdate();
        }
        else if (singleAction == "record") {
            reportrecordupdate();
        }
        else if (singleAction == "dashboard") {
            navigate("/dashboard")
        }

    }
    
    function detectMob() {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];

        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }

    const saveReport = async () => {

        setrequestProcessingModal(true)


        let dataDevice = detectMob();

        let devicesorce;

        if (dataDevice == true) {

            devicesorce = 2

        } else {
            devicesorce = 1

        }

        if (reportName?.length == 0 || reportName == undefined || reportName == "" || reportName == null) {
            setrequestProcessingModal(false)
            setrequiredField(true)
            return false
        } else {

            html2canvas(document.getElementById("chart-table")).then(function (canvas) {

                let timezone = new Date().getTimezoneOffset();
                let formDataReport = {
                    'pid': currentConfig,
                    'session_id': session,
                    'name': reportName,
                    'notes': notes,
                    'status': 1,
                    '_device': devicesorce,
                    'timezone': timezone,
                    'clock': showclock
                };

                fetch(API_URL + "/save/single/report", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': accessToken,
                    },
                    body: JSON.stringify(formDataReport),
                }).then(async (result) => {
                    let _res = await result.json();
                    let reportId = _res.reports.insertId;

                    for (const [i, v] of graphs.entries()) {
                        let _config = signalConfig[v.signal_name];
                        await new Promise((resolve) => setTimeout(resolve, i * 3000));

                        await fetch(API_URL + "/save/single/report/graph", {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'x-access-token': accessToken,
                            },
                            body: JSON.stringify({
                                reportId,
                                _config,
                                signal_name: v.signal_name
                            }),
                        });

                        if (i === graphs.length - 1) {
                            setChangesMade(false);
                            setrequestProcessingModal(false);
                            setrequestProcesedModal(true);
                            setSavingReportConfirmation(false);

                            setTimeout(() => {
                                performAction();
                            }, 3000);
                        }
                    }

                })


                // })






            });

        }


    }

    const handlesavereportinput = (e) => {
        setReportName(e.target.value)

        let inputVal = e.target.value;

        if (inputVal.length > 0) {
            setrequiredField(false)
        } else {
            setrequiredField(true)
        }
    }

    const saveReportConfig = () => {

        runSaveReportConfig();

    }


    const handlealtnameinput = (e) => {

        setAltName(e.target.value)
        let inputVal = e.target.value;

        if (inputVal.length > 0) {
            setrequiredField(false)
        } else {
            setrequiredField(true)
        }
    }

    const runSaveReportConfig = async () => {
        setrequestProcessingModal(true);
        setDefaultaltsave(1)

        if (altName?.length == 0 || altName == undefined || altName == "" || altName == null) {
            setrequestProcessingModal(false)
            setSavingAlternateConfirmation(true)
            setrequiredField(true)
            return false
        } else {

            console.log("config",config)

            let formData = {
                'original': config,
                'user': userId,
                'name': altName,
                'type': "1",
                'status': "1"
            };

            try {
                const response = await fetch(API_URL + "/save/single/alertnate/report/config", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': accessToken,
                    },
                    body: JSON.stringify(formData),
                });

                const result = await response.json();
                const alternateId = result.reports.insertId;

                for (let i = 0; i < graphs.length; i++) {
                    let v = graphs[i];
                    let _config = signalConfig[v.signal_name];

                    const graphResponse = await fetch(API_URL + "/save/single/alertnate/report/graph", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-access-token': accessToken,
                        },
                        body: JSON.stringify({
                            alternateId,
                            _config,
                            signal_name: v.signal_name
                        }),
                    });

                    await graphResponse.json();

                    if (i === graphs.length - 1) {
                        setrequestProcessingModal(false);
                        setrequestProcesedModal(true);
                        setDefaultaltsave(2)
                        // setTimeout(()=>{
                        //     window.location.reload();
                        // },3000)
                    }
                }
            } catch (error) {
                console.error(error);
                setrequestProcessingModal(false);
            }

        }



    };



    const [linkGraphs, setLinkGraphs] = useState(false);


    const [globalConfig, setGlobalConfig] = useState({
        color: '',
        signal: 1,
        type: '',
        avg: '',
        xmin: '',
        thick: 0.5,
        xextreme: '',
        xmax: '',
        ymin: 0,
        ymax: 50,
        record: '',
        graph_order: '',
        comment: '',
        row: '',
        clientSerial: '',
        col: '',
        xrange: 0,
        units: '',
        annotation: 1,
        grid: 2,
        showGrid: false,
        invert: 2,
        position: '',
        lineType: '',
        disabledType: '',
        signalType: '',
        thresholdtLine: false,
        thresholdtLineType: 'dot',
        thresholdtcolor: '',
        thresholdthick: '',
        thresholdvalue: '',

    });



    return (
        // <div onContextMenu={(e) => e.preventDefault()   } >
        <div  >
            {/* {
                graphs.length > 0  && showHeader &&
                <ChartHeader linkGraphs={linkGraphs} group={false} showHeader={showHeader}  showActualTime={showActualTime} setShowSignalStat={setShowSignalStat}  showSignalStat={showSignalStat} setSessionDate={setSessionDate} setSavingReportConfirmation={setSavingReportConfirmation} setrequestProcessingModal={setrequestProcessingModal}  setrequestProcesedModal={setrequestProcesedModal} setNotes={setNotes} graphs={graphs} signalStat={signalStat} notes={notes} exportExcel={exportExcel} saveReportConfig={() => setSavingAlternateConfirmation(!savingAlternateConfirmation)} config={config} />
            } */}
            {
                graphs.length > 0 &&
                <ChartHeader setChangesMade={setChangesMade} changesMade={changesMade} defaultaltsave={defaultaltsave} sendData={handleDataFromChild} zoomEnabled={zoomEnabled} setZoomEnabled={setZoomEnabled} dragMode={dragMode} setDragMode={setDragMode} isOpen={isOpen} setIsOpen={setIsOpen} linkGraphs={linkGraphs} group={false} showHeader={showHeader} showActualTime={showActualTime} setShowSignalStat={setShowSignalStat} showSignalStat={showSignalStat} setSessionDate={setSessionDate} setSavingReportConfirmation={setSavingReportConfirmation} setrequestProcessingModal={setrequestProcessingModal} setrequestProcesedModal={setrequestProcesedModal} setNotes={setNotes} graphs={graphs} signalStat={signalStat} notes={notes} exportExcel={exportExcel} saveReportConfig={() => setSavingAlternateConfirmation(!savingAlternateConfirmation)} config={config} />
            }



            <div className="wrp-charttable" id="chart-table">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        {
                            graphs.length > 0 && sessionDate && graphs.map(function (d, i) {

                                return (

                                    <div className="chart-w" style={{ width: (d.col != "1/1" ? (eval((d.col)) * 99) + "%" : (eval(d.col) * 100) + "%"), maxWidth: (eval(d.col) * 100) + "%", height: "auto", marginTop: "30px", minHeight: (eval(d.row) * 82) + "vh" }}>
                                        {
                                            showsci ?
                                         <ChartSci setChangesMade={setChangesMade}  dragMode={dragMode} zoomEnabled={zoomEnabled} isOpen={isOpen} setIsOpen={setIsOpen} linkGraphs={linkGraphs} globalConfig={globalConfig} setGlobalConfig={setGlobalConfig} sessionDate={sessionDate} group={false} showActualTime={showActualTime} showSignalStat={showSignalStat} setStats={setStats} col={d.col} row={d.row} setConfig={setConfig} record={record} session={session} signalO={d.signal_name} signal={d.signal_name} xmax={d.xmax} xmin={d.xmin} ymin={d.ymin} ymax={d.ymax} thick={d.thick} otherConfig={d.other_config} graph_order={d.graph_order} type={d.type} color={d.color} /> 

                                            :
                                         <Chart setChangesMade={setChangesMade}  dragMode={dragMode} zoomEnabled={zoomEnabled} isOpen={isOpen} setIsOpen={setIsOpen} linkGraphs={linkGraphs} globalConfig={globalConfig} setGlobalConfig={setGlobalConfig} sessionDate={sessionDate} group={false} showActualTime={showActualTime} showSignalStat={showSignalStat} setStats={setStats} col={d.col} row={d.row} setConfig={setConfig} record={record} session={session} signalO={d.signal_name} signal={d.signal_name} xmax={d.xmax} xmin={d.xmin} ymin={d.ymin} ymax={d.ymax} thick={d.thick} otherConfig={d.other_config} graph_order={d.graph_order} type={d.type} color={d.color} /> 


                                         }
                                    </div>


                                )

                            })
                        }



                    </div>
                </div>
            </div>



            {/* request processing modal */}

            <Modal isOpen={requestProcessingModal} toggle={requestProcessingModalToggle} className="modal-box-wrp" centered={true}>
                <ModalHeader toggle={requestProcessingModalToggle}><span className="ml-1 roititle modal-head">Request processing...</span></ModalHeader>
                <ModalBody>
                    <p className='text-center'>Please wait...</p>
                    <div className="wrp-chart-loader">
                        <div class="loading">
                            <div class="loading-1"></div>
                            <div class="loading-2"></div>
                            <div class="loading-3"></div>
                            <div class="loading-4"></div>
                        </div>
                    </div>
                </ModalBody>

            </Modal>

            {/* request processing modal  */}


            {/* request processed modal */}

            <Modal isOpen={requestProcessedModal} toggle={requestProcessedModalToggle} className="modal-box-wrp" centered={true}>
                <ModalHeader toggle={requestProcessedModalToggle}><span className="ml-1 roititle modal-head">Request processed.</span></ModalHeader>
                <ModalBody>
                    <p className='text-center'>Your request has been processed succesfully.</p>

                </ModalBody>

            </Modal>

            {/* request processed modal  */}



            {/* alternate report confirmation  modal */}

            <Modal isOpen={savingAlternateConfirmation} toggle={savingAlternateConfirmationToggle} className="modal-box-wrp" centered={true}>
                <ModalHeader toggle={savingAlternateConfirmationToggle}><span className="ml-1 roititle modal-head">Confirm request.</span></ModalHeader>
                <ModalBody>
                    <p className=''>Please enter the name of alternate configuration you want to save.</p>
                    <input type="text" class="form-control" value={altName} onChange={handlealtnameinput} placeholder="Report Name" aria-label="Report Name" aria-describedby="basic-addon1" />
                    <br></br>
                    {requiredField && <p className='requirdfield'>Required Field</p>}
                    <div className='d-flex justify-content-around mt-3'>
                        <button className='lightbtn w-100' onClick={savingAlternateConfirmationToggle} >Cancel</button>
                        <button className='darktbtn w-100 ml-1' onClick={saveReportConfig} >Save</button>
                    </div>
                </ModalBody>

            </Modal>

            {/* alternate report confirmation  modal  */}





            {/* savng report confirmation modal */}

            <Modal isOpen={savingReportConfirmation} toggle={savingReportConfirmationToggle} className="modal-box-wrp" centered={true}>
                <ModalHeader toggle={savingReportConfirmationToggle}><span className="ml-1 roititle modal-head">Confirm request.</span></ModalHeader>
                <ModalBody>
                    <p className=''>Please enter the name of report you want to save.</p>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">{sessionDate}</span>
                        </div>
                        <input type="text" class="form-control" value={reportName} onChange={handlesavereportinput} placeholder="Report Name" aria-label="Report Name" aria-describedby="basic-addon1" />

                    </div>

                    {requiredField && <p className='requirdfield'>Required Field</p>}

                    <div className='d-flex justify-content-around mt-3'>
                        <button className='lightbtn w-100' onClick={savingReportConfirmationToggle} >Cancel</button>
                        <button className='darktbtn w-100 ml-1' onClick={saveReport} >Save</button>
                    </div>
                </ModalBody>

            </Modal>

            {/* saving report confirmation  modal  */}

        </div>
    )
}

export default ChartTable;