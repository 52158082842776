import React, { Component, useEffect, useState } from 'react';
import { Row, Col, Container, Button, ModalHeader, ModalFooter, Modal, ModalBody } from "reactstrap";
import html2canvas from 'html2canvas';

import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import InputColor from 'react-input-color';
import Plots from 'react-plotly.js';
import { csv } from 'd3';
import ViewChartHeader from '../../component/ViewChartHeader';
import Chart from './Chart';
import { API_URL } from '../../../config';

const MultiChartTable = () => {

    const location = useLocation()

    const viewcommentid = location?.state?.viewmultiids;
    const [changesMade, setChangesMade] = useState(false)
    const [multiaction, setMultiaction] = useState()
    


  const handleDataFromChild = (data) => {
    setMultiaction(data);
  };

    const accessToken = localStorage.getItem('accessToken');
    const { reportId, showclock , created } = useParams();
    const record = "all"
    const [currentConfig, setCurrentConfig] = useState()
    const clientId = localStorage.getItem('selectedClient');

    const [graphs, setgraphs] = useState([]);
    // const [sessionNumber, setSessionNumber] = useState(0);

    const [notes, setNotes] = useState(null);
    const [reportName, setReportName] = useState(null);
    const [savereporterror, setSavereporterror] = useState(null)
    const [showHeader, setShowHeader] = useState(false);
    const [sessionDate, setSessionDate] = useState(null);
    const userId = localStorage.getItem('user_id');
    const [showActualTime, setShowActualTime] = useState(true);
    const [refreshMulti, setRefreshMulti] = useState(null);
    const [requiredField, setrequiredField] = useState(false);
    const [zoomEnabled, setZoomEnabled] = useState(true);

    // const [value, setValue] = useState(0);
    // const [point, setPoint] = useState(25);
    // const [color, setColor] = useState();
    const [signalConfig, setSignalConfig] = useState({})
    const [signalStat, setSignalStat] = useState({})
    const [showSignalStat, setShowSignalStat] = useState(false)
    const navigate = useNavigate();

    const [requestProcessingModal, setrequestProcessingModal] = useState(false);
    const requestProcessingModalToggle = () => setrequestProcessingModal(!requestProcessingModal);

    const [savingReportConfirmation, setSavingReportConfirmation] = useState(false);
    const savingReportConfirmationToggle = () => setSavingReportConfirmation(!savingReportConfirmation);

    const [savingAlternateConfirmation, setSavingAlternateConfirmation] = useState(false);
    const savingAlternateConfirmationToggle = () => setSavingAlternateConfirmation(!savingAlternateConfirmation);

    const [linkingType, setLinkingType] = useState('')
    // const [linkingType,setLinkingType] = useState(null)

    const [requestProcessedModal, setrequestProcesedModal] = useState(false);
    const requestProcessedModalToggle = () => setrequestProcesedModal(!requestProcessedModal);

    const [dragMode, setDragMode] = useState('pan');

    const [main, setMain] = useState({});

    const setConfig = (_signal, data) => {
        let _temp = signalConfig;
        _temp[_signal] = {
            color: data.color,
            type: data.type,
            avg: data.avg,
            xmin: data.xmin / 1e3,
            signal: data.signal,
            thick: data.thick,
            xextreme: data.xextreme / 1e3,
            xmax: data.xmax / 1e3,
            ymin: data.ymin,
            ymax: data.ymax,
            record: data.record,
            graph_order: data.graph_order,
            comment: data.comment,
            row: data.row,
            clientSerial: data.clientSerial,
            col: data.col,
            xrange: data.xrange,
            units: data.units,
            annotation: data.annotation,
            grid: data.grid,
            stat: data.stat,
            inverty: data.inverty,
            yposition: data.yposition,
            lineType: data.lineType
        }

        setMain({
            color: data.color,
            type: data.type,
            avg: data.avg,
            xmin: data.xmin / 1e3,
            signal: data.signal,
            thick: data.thick,
            xextreme: data.xextreme / 1e3,
            xmax: data.xmax / 1e3,
            ymin: data.ymin,
            ymax: data.ymax,
            record: data.record,
            graph_order: data.graph_order,
            comment: data.comment,
            row: data.row,
            clientSerial: data.clientSerial,
            col: data.col,
            xrange: data.xrange,
            units: data.units,
            annotation: data.annotation,
            grid: data.grid,
            inverty: data.inverty,
            yposition: data.yposition,
            lineType: data.lineType
        })
        setSignalConfig(_temp)

    }

    const setStats = (_signal, data) => {
        let _temp = signalStat;
        let _tempData = [];
        data.map((v, i) => {
            _tempData.push({
                x: v.x,
                mean: v.mean,
                median: v.median,
                sd: v.sd,
            })
        })
        _temp[_signal] = _tempData;
        setSignalStat(_temp)
        setTimeout(() => {
            setShowHeader(true);
        }, 1000 * graphs.length);
    }

    useEffect(() => {
        reportChart();
        getReportDetails();

    }, []);

    const getReportDetails = () => {

        let url = API_URL + "/view/multi/report/details?id=" + reportId

        fetch(url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
            }
        ).then((response) => {
            if (response.status == 200) {
                response.json().then((resp) => {
                    if (resp.details[0]) {
                        setReportName(resp.details[0].name);

                    }
                })
            }
        })
    }

    const reportChart = () => {
        fetch(API_URL + "/view/report/multi/config?report_id=" + reportId,

            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
            }
        ).then((response) => {
            if (response.status == 200) {
                response.json().then((resp) => {
                    setgraphs(resp.graphs)
                    // setSessionNumber(3)

                    // if (resp.graphs.length % 3 == 0) {
                    //     setSessionNumber(3)
                    // }
                    // if (resp.graphs.length % 2 == 0) {
                    //     setSessionNumber(2)
                    // }


                });
            }
            else if (response.status == 401) {
                logout()
            }
            else {
                console.log("network error")
            }



        })
    }
    const logout = () => {
        localStorage.clear();
        window.location.reload();
    }


    const exportExcel = () => {
        setrequestProcesedModal(true);

    }


    const performAction = () => {
        if (multiaction == "reportConfig") {
            reportconfigupdate();
        }
        else if (multiaction == "altConfig") {
            reportconfigalternateupdate();
        }
        else if (multiaction == "record") {
            reportrecordupdate();
        }
        else if(multiaction == "dashboard" ){
            navigate("/view/data/report")
        }
        else if (multiaction == "dashboardm") {
            if(created == "1"){
                navigate("/dashboard")
            }
            else{
            navigate("/view/data/report")

            }
        }

    }

    function detectMob() {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];

        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }
    

    const saveReport = async () => {
        setrequestProcessingModal(true);

        let dataDevice = detectMob();

        let devicesorce;

        if (dataDevice == true) {

            devicesorce = 2

        } else {
            devicesorce = 1

        }

        if (reportName?.length == 0 || reportName == undefined || reportName == "" || reportName == null) {
            setrequestProcessingModal(false)
            setSavereporterror(true)
            return false
        } else {

            try {
                let timezone = new Date().getTimezoneOffset();
                let formDataReport = {
                  'rid': reportId,
                  'notes': notes,
                  'timezone': timezone,
                  'name': reportName,
                  'cid': clientId,
                  'clock': showclock,
                  '_device': devicesorce,
                };
            
                const responseReport = await fetch(API_URL + "/save/multi/report", {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                  },
                  body: JSON.stringify(formDataReport),
                });
            
                const _resReport = await responseReport.json();
            
                for (let i = 0; i < graphs.length; i++) {
                  let v = graphs[i];
                  let _config = signalConfig[v.signal_name + "_" + v.sid];
            
                  const responseGraph = await fetch(API_URL + "/save/multi/report/graph", {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'x-access-token': accessToken,
                    },
                    body: JSON.stringify({
                      reportId,
                      _config,
                      sid: v.sid,
                      formDataReport
                    }),
                  });
            
                  const _resGraph = await responseGraph.json();
            
                  if (i === graphs.length - 1) {
                    setrequestProcessingModal(false);
                    setrequestProcesedModal(true);
                    setSavingReportConfirmation(false);
                    setChangesMade(false)
                    setTimeout(() => {
                      performAction();
                    }, 3000);
                  }
                }
              } catch (error) {
                console.error(error);
                setrequestProcessingModal(false);
              }

        }
      
        
      };
      

    const Handlesavereportinput = (e) => {

        setReportName(e.target.value)

        let inputVal = e.target.value

        if (inputVal.length > 0) {

            setSavereporterror(false)

        } else {
            setSavereporterror(true)
        }



    }





    const [linkGraphs, setLinkGraphs] = useState(false)

    const [globalConfig, setGlobalConfig] = useState({
        color: '',
        signal: 1,
        type: '',
        avg: '',
        xmin: '',
        thick: 0.5,
        xextreme: '',
        xmax: '',
        ymin: 0,
        ymax: 50,
        record: '',
        graph_order: '',
        comment: '',
        row: '',
        clientSerial: '',
        col: '',
        xrange: 0,
        units: '',
        annotation: 1,
        grid: 2,
        showGrid: false,
        invert: 2,
        position: '',
        lineType: '',
        disabledType: '',
        signalType: '',
        thresholdtLine: false,
        thresholdtLineType: 'dot',
        thresholdtcolor: '',
        thresholdthick: '',
        thresholdvalue: '',
        signalName: '',
        session: ''

    });

    const saveReportConfig = () => {
        setSavingAlternateConfirmation(false)
        runSaveReportConfig();

    }

    const runSaveReportConfig = () => {
        setrequestProcessingModal(true)
        let formData = {
            'original': config,
            'user': userId,
            'name': "Alternate",
            'type': "1",
            'status': "1"
        };

        fetch(API_URL + "/save/single/alertnate/report/config", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            },
            body: JSON.stringify(formData),
        }).then(async (result) => {
            let _res = await result.json();
            let alternateId = _res.reports.insertId;

            graphs.map((v, i) => {
                let _config = signalConfig[v.signal_name];
                fetch(API_URL + "/save/single/alertnate/report/graph", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': accessToken,
                    },
                    body: JSON.stringify({
                        alternateId,
                        _config,
                        signal_name: v.signal_name
                    }),
                }).then(async (result) => {
                    let _res = await result.json();


                    if (i == (graphs.length - 1)) {
                        setrequestProcessingModal(false);
                        setrequestProcesedModal(true)
                    }


                })
            })


        })

    }

    const [globalchanges, setGlobalchanges] = useState('');
    const [globalchangescolor, setGlobalchangescolor] = useState('');

    // Callback function to receive data from the child
    const handleglobalchnagescolor = (data) => {
        setGlobalchangescolor(data);
    };

    const handleglobalchnages = (data) => {
        setGlobalchanges(data);
    };

    let   sessid = 0 ; 
    let showName = false ;
    return (
        <div>
            {
                graphs.length > 0 &&
                <ViewChartHeader changesMade={changesMade} setChangesMade={setChangesMade} globalchangescolor={globalchangescolor} globalchanges={globalchanges} sendData={handleDataFromChild} linkGraphs={linkGraphs} setLinkGraphs={setLinkGraphs} setShowActualTime={setShowActualTime} showActualTime={showActualTime} setShowSignalStat={setShowSignalStat} linkingType={linkingType} setLinkingType={setLinkingType} showSignalStat={showSignalStat} setSavingReportConfirmation={setSavingReportConfirmation} setrequestProcessingModal={setrequestProcessingModal} setrequestProcesedModal={setrequestProcesedModal} setNotes={setNotes} graphs={graphs} signalStat={signalStat} notes={notes} exportExcel={exportExcel} saveReportConfig={() => setSavingAlternateConfirmation(!savingAlternateConfirmation)} multi={true} config={currentConfig} zoomEnabled={zoomEnabled} setZoomEnabled={setZoomEnabled} dragMode={dragMode} setDragMode={setDragMode} />
            }
 


            <div className="wrp-charttable" id="chart-table">
                <div className="container-fluid">
                    <div className="row justify-content-between">
 
                        {
                            
                            graphs.length > 0  && graphs.map(function (d, i) {
                                showName = false ; 
                                  if(sessid != d.sid){
                                    sessid = d.sid ; 
                                    showName = true ;
                                  }
                                return (


                                    <div className="chart-w" style={{ width: ((d.col != "1/1" && d.col != "1") ? (eval((d.col)) * 99) + "%" : (eval(d.col) * 100) + "%"), maxWidth: (eval(d.col) * 100) + "%", height: "auto", minHeight: (eval(d.row) * 84) + "vh" }}>
                                   
                                        {
                                            showName &&
                                            <p className={d.col === "1/1" ? "flyleft2 moreflyleft2" : d.col === "1/2" ? "flyleft1" : "flyleft"} >{d.session_name}</p>
                                        }
                                        
                                        {
                                            <Chart changesMade={changesMade} setChangesMade={setChangesMade} viewcommentid={viewcommentid} handleglobalchnagescolor={handleglobalchnagescolor} handleglobalchnages={handleglobalchnages} dragMode={dragMode} zoomEnabled={zoomEnabled} linkGraphs={linkGraphs} setLinkGraphs={setLinkGraphs} globalConfig={globalConfig} setGlobalConfig={setGlobalConfig} linkingType={linkingType} multi={true} group={false} showActualTime={showActualTime} showSignalStat={showSignalStat} comment={d.comment} setStats={setStats} col={d.col} row={d.row} setConfig={setConfig} record={record} session={d.sid} signalO={d.signal_name} signal={d.signal_name} xmax={d.xmax} xmin={d.xmin} ymin={d.ymin} ymax={d.ymax} thick={d.thick} otherConfig={d.other_config} graph_order={d.graph_order} type={d.type} color={d.color} />
                                        }
                                    </div>


                                )
                             
                            })
                        }



                    </div>
                </div>
            </div>



            {/* request processing modal */}

            <Modal isOpen={requestProcessingModal} toggle={requestProcessingModalToggle} className="modal-box-wrp" centered={true}>
                <ModalHeader toggle={requestProcessingModalToggle}><span className="ml-1 roititle modal-head">Request processing...</span></ModalHeader>
                <ModalBody>
                    <p className='text-center'>Please wait...</p>
                    <div className="wrp-chart-loader">
                        <div class="loading">
                            <div class="loading-1"></div>
                            <div class="loading-2"></div>
                            <div class="loading-3"></div>
                            <div class="loading-4"></div>
                        </div>
                    </div>
                </ModalBody>

            </Modal>

            {/* request processing modal  */}


            {/* request processed modal */}

            <Modal isOpen={requestProcessedModal} toggle={requestProcessedModalToggle} className="modal-box-wrp" centered={true}>
                <ModalHeader toggle={requestProcessedModalToggle}><span className="ml-1 roititle modal-head">Request processed.</span></ModalHeader>
                <ModalBody>
                    <p className='text-center'>Your request has been processed succesfully.</p>

                </ModalBody>

            </Modal>

            {/* request processed modal  */}



            {/* alternate report confirmation  modal */}

            <Modal isOpen={savingAlternateConfirmation} toggle={savingAlternateConfirmationToggle} className="modal-box-wrp" centered={true}>
                <ModalHeader toggle={savingAlternateConfirmationToggle}><span className="ml-1 roititle modal-head">Confirm request.</span></ModalHeader>
                <ModalBody>
                    <p className='text-center'>Are you sure you want to save the current configuration as an alternate configuration of this report?</p>
                    <div className='d-flex justify-content-around mt-3'>
                        <button className='lightbtn w-100' onClick={savingAlternateConfirmationToggle} >Cancel</button>
                        <button className='darktbtn w-100 ml-1' onClick={saveReportConfig} >Save</button>
                    </div>
                </ModalBody>

            </Modal>

            {/* alternate report confirmation  modal  */}





            {/* savng report confirmation modal */}

            <Modal isOpen={savingReportConfirmation} toggle={savingReportConfirmationToggle} className="modal-box-wrp" centered={true}>
                <ModalHeader toggle={savingReportConfirmationToggle}><span className="ml-1 roititle modal-head">Confirm request.</span></ModalHeader>
                <ModalBody>
                    <p className=''>Name of report.</p>

                    <div class="input-group mb-3">

                        <input type="text" class="form-control" value={reportName} onChange={Handlesavereportinput} placeholder="Report Name" aria-label="Report Name" aria-describedby="basic-addon1" />
                    </div>

                    {
                        savereporterror && <p className='requirdfield'>Required Field</p>
                    }

                    <div className='d-flex justify-content-around mt-3'>
                        <button className='lightbtn w-100' onClick={savingReportConfirmationToggle} >Cancel</button>
                        <button className='darktbtn w-100 ml-1' onClick={saveReport} >Save</button>
                    </div>
                </ModalBody>

            </Modal>

            {/* saving report confirmation  modal  */}

        </div>
    )
}

export default MultiChartTable;